<template>
  <div style="background-color: #f8f8fd;min-height: 100vh">
    <back-nav class="title_top" :icon="'img/backb.png'" :textColor="'#111'" :backColor="'none'" />
    <div class="father_box_" v-if="active1 == 0">
      <div
        :class="item.index == index_ ? 'child_tabs_click_' : 'child_tabs_'"
        v-for="(item, index) in tabs"
        :key="index"
        @click="tabClick(item.title, item.index)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="noData" v-if="list.length == 0">
      <img src="img/noData.png" alt="" />
    </div>
    <template v-if="list.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="10"
        >
          <div class="cash_item_" v-for="(item, index) in list" :key="index">
            <div
              class="cash_time display_flex justify-content_flex-justify align-items_center"
            >
              <span style="color: rgba(0, 0, 0, 1)">{{
                item.diamonds_name
              }}</span>
              <span style="color: rgba(0, 0, 0, 0.4)">{{
                item.created_at
              }}</span>
            </div>
            <div class="cell-bottom">
              <div class="cell-bottom-top">
                <span style="color: #111">{{ item.diamonds_name }}</span>
              </div>
              <div style="flex: 1" class="cell-bottom-bottom">
                <div class="cell-bottom-right-top">
                  <img class="diamond_big" src="img/bi.png" alt="" />
                  <span style="color: #111">{{ item.text_number }}</span>
                </div>
                <div class="cell-bottom-right-bottom">
                  <span style="color: #111">余额：</span>
                  <img class="diamond" src="img/bi.png" alt="" />
                  <span style="color: #111 !important">{{
                    item.balance
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </template>
  </div>
</template>
<script>
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import "@/assets/css/game.css";
import { DiamondRecordLog } from "@/api/api";
import BackNav from "@/components/backNav.vue";
export default {
  components: {
    BackNav,
  },
  data() {
    return {
      rows: "10",
      id: "0",
      active1: 0,
      index_: 0,
      list: [],
      type: 1,
      tabs: [
        { title: "获得", index: 0 },
        { title: "支出", index: 1 },
      ],
      loading: false,
      finished: false,
      refreshing: false,
      from: "",
    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    // this.getDiamondLog(1, 0)
    this.onLoad();
  },
  methods: {
    getDiamondLog() {
      let requestData = {
        rows: this.rows,
        log_id: this.id,
        type: this.type,
      };
      DiamondRecordLog(requestData).then((res) => {
        this.loading = false;
        if (this.id == "0") {
          this.list = res.data;
        } else {
          this.list = this.list.concat(res.data);
        }
        if (this.list.length > 0) {
          this.id = this.list[this.list.length - 1].id;
        }
        if (res.data.length < 10) {
          this.finished = true;
        }
      });
    },
    closePage() {
      if (this.from == "h5") {
        this.$router.go(-1);
      } else {
        let params = "调用";
        this.$bridge.callhandler("closePage", params, (data) => {
          // 处理返回数据
        });
      }
    },
    // change(name, title) {
    //     console.log(name)
    //     this.type = name
    //     this.getDiamondLog(name, 0)
    // },
    tabClick(title, index) {
      this.list = [];
      this.index_ = index;
      this.finished = false;
      this.id = "0";
      if (title == "获得") {
        this.type = 1;
        this.getDiamondLog();
      } else {
        this.type = 2;
        this.getDiamondLog();
      }
    },
    onLoad() {
      this.getDiamondLog();
    },
    onRefresh() {
      this.list = [];
      this.id = "0";
      this.finished = false;
      this.getDiamondLog();
    },
  },
};
</script>
<style scoped>
.panel-footer {
  font-size: 26px;
  text-align: right;
}

.cell-bottom {
  display: flex;
  width: 100%;
  height: 150px;
}

.cell-bottom-top {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
}

.diamond {
  width: 34px;
  height: 30px;
}

.diamond_big {
  width: 38px;
  height: 34px;
}

.cell-bottom-bottom {
  padding: 0 30px 0 30px;
}

.cell-bottom-right-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.cell-bottom-right-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}
</style>